import { template as template_15d23ab463a14db1878ffe8a5587fe15 } from "@ember/template-compiler";
const FKText = template_15d23ab463a14db1878ffe8a5587fe15(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
