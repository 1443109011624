import { template as template_e5d2e5240c7a453c9476d60a067bdb9f } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class MessagesSecondaryNav extends Component {
    get messagesNav() {
        return document.getElementById("user-navigation-secondary__horizontal-nav");
    }
    static{
        template_e5d2e5240c7a453c9476d60a067bdb9f(`
    {{#in-element this.messagesNav}}
      {{yield}}
    {{/in-element}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
